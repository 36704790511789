import React from "react"
import SearchResultsTemplate from "../../templates/search-results-template"
import { propertyForRentURL } from "../../site/urls"

const PropertyRent = props => {
  const location = props.location

  return (
    <SearchResultsTemplate
      locationName={propertyForRentURL}
      location={location}
      pCategoryType="residential"
      pType="lettings"
      pSubPath="for-rent"
      pStatus="To Let"
    />
  )
}

export default PropertyRent
